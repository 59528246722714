import "./App.scss";
import "../node_modules/video-react/dist/video-react.css";
import Main from "./pages/Main";
import React, { useEffect, useState } from "react";
import "react-jinke-music-player/assets/index.css";
import { Routes, Route } from "react-router-dom";
import Biography from "./pages/Biography";


function App() {
  useEffect(() => {
    localStorage.clear()
  }, [])

  return (
    <div className="App">
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/biography" element={<Biography />} />
        </Routes>
    </div>
  );
}

export default App;

import React from "react";
import { useState } from "react";
import "./biography.scss";
import { Link } from "react-router-dom";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";

function Biography() {
  const music = localStorage.getItem("musicSrc")
  function player(){
    
    return (
      <div>
        <ReactJkMusicPlayer
          clearPriorAudioLists={true}
          autoPlay={true}
          audioLists={[{ musicSrc: `${music}` }]}
          theme="dark"
          showDownload={false}
          showPlayMode={false}
          mode="full"
          toggleMode={false}
          showThemeSwitch={false}
        />
      </div>
    )
  }
  const [ball, setBall] = useState(false);
  return (
    <div className="wrapper">
      <Link to="/">
        <div
          className="ballDiv"
          onMouseOver={() => {
            setBall(true);
          }}
          onMouseLeave={() => {
            setBall(false);
          }}
        >
          <div className="clickDiv">
            <div className={ball ? "active" : "ball"}></div>
          </div>
        </div>
      </Link>
      <div className="text">
        <h1>Roland Shalamberidze</h1>
        <p>Personal biography</p>
        <br />
        <p>
          Roland Shalamberidze is the founder of the UPPERGROUN movement, the
          concept of which is an extremely modern perception of the world -
          divine and cosmic artifacts, as well as empirical artifacts and
          natural physical objects, which is focused on the formation of a new
          artistic vision. The presentation of this course took place in the ART
          LIGA gallery in 2007 in St. Petersburg.
          <br />
          Roland Shalamberidze is also a laureate of the 1994 St. Petersburg
          Festival of Avant-Garde Poets.
          <br />
          Roland Shalamberidze was born in 1958 in Kutaisi, Georgia. In 1982, he
          and Merab Amaglobeli founded the non-conformist creative club of
          artists and poets &quot;White Wings&quot; in the city of Kutaisi.
          <br />
          Roland Shalamberidze lives and works in St. Petersburg since 1988. He
          works as an abstractionist, conceptualist, performance artist,
          installer, sound and video artist. His creative path began with
          successful experiments in figurative painting, but in the end he
          devoted his work to abstract expressionism and conceptualism. From
          1996 to 2001 he lived in Germany, then in New York, worked a lot under
          the influence of different ideas. In 2002 he returned to St.
          Petersburg. In his work, he uses mixed techniques and materials,
          combines painting, photography, collages, does not stop there, as he
          is sure that &quot;monotony is equal to death for the artist.&quot;
          Currently works in the St. Petersburg Art Center &quot;Pushkinskaya
          10&quot; and in his own workshop in Tbilisi.
          <br />
          Personal exhibitions:
          <br />
          2017 - TBS Art Gallery, Tbilisi, Georgia
          <br />
          2016 - M Gallery, Skagen, Morocco
          <br />
          2016 - L/LARTOTHEQUE, Casablanca, Morocco
          <br />
          2014 - L/LARTOTHEQUE, Casablanca, Morocco
          <br />
          2012 - L/LARTOTHEQUE, Casablanca, Morocco
          <br />
          2011 - Didi Gallery, St. Petersburg, Russia
          <br />
          2010 - L/LARTOTHEQUE, Casablanca, Morocco
          <br />
          2010 - Gallery d'ArtAU9, Casablanca, Morocco
          <br />
          2009 - Didi Gallery, St. Petersburg, Russia
          <br />
          2008 - Gallery d'ArtAU9, Casablanca, Morocco
          <br />
          2007 - Exhibition Center of Young Artists, St. Petersburg, Russia
          <br />
          2005 - Gallery Didi Gallery, St. Petersburg, Russia
          <br />
          2005 - Industrial Hangar, St. Petersburg, Russia
          <br />
          2002 - Art&amp;You Gallery, St. Petersburg, Russia
          <br />
          2001 - LDM Youth Palace, St. Petersburg, Russia
          <br />
          2000 - Culture Center &quot;Pushkinskaya 10&quot;,
          &quot;Poligon&quot;, St. Petersburg, Russia
          <br />
          2000 - Studio &quot;SHAL&quot; 511, St. Petersburg, Russia
          <br />
          2000 - Joseph Lloyd Design Center, New York, USA
          <br />
          1999 - Citibank, Queens, New York, USA
          <br />
          1996 - Studio &quot;SHAL&quot; 511, St. Petersburg, Russia
          <br />
          1995 - Computer Center, Copenhagen, Denmark
          <br />
          1994 - Gallery 103, St. Petersburg, Russia
          <br />
          1993 - Nikolaev Palace, St. Petersburg, Russia
          <br />
          1992 - Financial Center, Tampere, Finland
          <br />
          1992 - Gallery 10/10, St. Petersburg, Russia
          <br />
          1991 - New Solyanka Exhibition Center, Moscow, Russia
          <br />
          1985 - Gallery of David Kakabadze., Kutaisi, Georgia
        </p>
        <br />
        <p>Selected group exhibitions:</p>
        <br />
        <p>
          2017 &ndash; &quot;Icons of the Russian Revolution&quot;, Geneva,
          Switzerland
          <br />
          2017 - Moma, Tbilisi, Georgia
          <br />
          2016 - Museum of Geology, Moscow, Russia
          <br />
          2016 - Gallery &quot;M&quot;, Skagen, Denmark
          <br />
          2014 - &quot;Kathedralen&quot; gallery, Skagen, Denmark
          <br />
          2014 - Museum of Nonconformist Art, St. Petersburg, Russia
          <br />
          2013 - Asa Art Group, St. Petersburg, Russia
          <br />
          2013 IFA St. Petersburg, Russia
          <br />
          2013 &ndash; &quot;Manezh&quot;, St. Petersburg, Russia
          <br />
          2011 &ndash; &quot;Art Moscow&quot;, 15th international art
          exhibition, Moscow, Russia
          <br />
          2010 &ndash; &quot;Space in Siberia and the Far West&quot;,
          Khantymansiysk, Omsk, Barnaul, Krasnoyarsk, Khabarovsk, Vladivostok,
          Russia
          <br />
          2009 - &quot;Sea Level&quot;, independent international art festival,
          St. Petersburg, Russia
          <br />
          2007 - Central Exhibition Hall &quot;Manezh&quot;, St. Petersburg,
          Russia
          <br />
          2006 &ndash; &quot;Kulturforum Lysover Lolland&quot;, Copenhagen,
          Denmark
          <br />
          2006 - Ethnographic Museum &quot;Artindex&quot;, St. Petersburg,
          Russia
          <br />
          2005 - &quot;Kathedralen&quot; gallery, Skagen, Denmark
          <br />
          2004 - Central Exhibition Hall &quot;Manezh&quot;, St. Petersburg,
          Russia
          <br />
          2004 - &quot;Kathedralen&quot; gallery, Skagen, Denmark
          <br />
          2002 Central Exhibition Hall &quot;Manezh&quot;, St. Petersburg,
          Russia
          <br />
          2001 - Russian State Museum, exhibition &quot;20th century
          abstractionism&quot;, St. Petersburg, Russia
          <br />
          2000 &ndash; G-Real Art Gallery, New York, USA
          <br />
          2000 - 2004 - &quot;Cathedralen&quot; gallery, Skagen, Denmark
          <br />
          1999 - Gallery Artforum, New York, USA
          <br />
          1999 - Ward-Nassw Gallery, New York, USA
          <br />
          1998 - &quot;Zalman&quot; Gallery, New York, USA
          <br />
          1998 - United Nations Center, New York, USA
          <br />
          1998 - Dagmar Behringer Gallery, Munich, Germany
          <br />
          1997 - &quot;Zalman&quot; Gallery, New York, USA
          <br />
          1996 - Peter Mitz - Kraling Gallery, Munich, Germany
          <br />
          1996 - Gallery &quot;Im Anbau&quot;, Munich, Germany
          <br />
          1996 - Culture Center &quot;Cabel Fabric&quot;, Helsinki, Finland
          <br />
          1995 - New Art Center (BYK), Vienna, Austria
          <br />
          1995 &ndash; A.R.G.E. Salzburg, Austria
          <br />
          1994 - Gallery 103, St. Petersburg, Russia
          <br />
          1994 - Gallery 21, St. Petersburg, Russia
          <br />
          1993 - Gallery &quot;T&amp;I&quot;, Berlin, Germany
          <br />
          1993 - Akhmatova Museum, St. Petersburg, Russia
          <br />
          1993 &ndash; Nicholas Palace, St. Petersburg, Russia
          <br />
          1993 - Stockforce, Muktia, Finland
          <br />
          1992 - Pankow Gallery, Rockefeller Center, Berlin, Germany
          <br />
          1991 - the first biennale of modern art, St. Petersburg, Russia
          <br />
          1991 - Academy of Modern Cinema, Paris, France
        </p>
        <br />
        <p>Musical resume</p>
        <br />

        <p>
          In 1991, Roland Shalamberidze founded the string duo &quot;LC&quot;
          (R. Shalamberidze, B. Lomsianidze) in St. Petersburg.
          <br />
          In 2001, he created a band called &quot;WHITE OUT&quot;. Famous St.
          Petersburg musicians were involved in this project.
          <br />
          The band &quot;WHITE OUT&quot; participated in the following music
          festivals held in various artistic spaces of Europe:
          <br />
          1993 - Kreiling, Germany
          <br />
          1993 - Munich, Germany
          <br />
          1994 - Berlin, Germany
          <br />
          1994 - Dresden, Germany
          <br />
          1994 - Vienna, Austria
          <br />
          1994 - Linz, Austria
          <br />
          1994 - Innsbruck, Austria
          <br />
          1994 - Salzburg, Austria
        </p>
        <p>
          in St. Petersburg (optional)
          <br />
          2015 &ndash; G.E.S Gallery of Experimental Music
          <br />
          2014 &ndash; &quot;FISH FABRIQUE&quot; art center. Petersburg, Russia
          <br />
          2011 - G.E.S Gallery of Experimental Music
          <br />
          2008 - Museum of Nonconformist Art. Pushkini 10, Art Center.
          <br />
          2007 - State Museum of Russia
          <br />
          2006 - Museum of Freud's Dreams
          <br />
          2005 - Central exhibition hall &quot;MANEGE&quot;
          <br />
          2005 &ndash; &quot;SLAVIANKA ANGAR&quot;
          <br />
          2004 - Peter and Paul Fortress, St. Petersburg
          <br />
          2003 - International Music Festival &quot;SKIFF&quot;
          <br />
          2002 - &quot;Kronstadt&quot; sailors' palace
          <br />
          2002 - Gallery 21, Pushkini 10
          <br />
          2002 - University of Trade Unions
        </p>
        <br />
        <p>Manuals and works in private collections:</p>
        <br />
        <p>
          1 - &quot;State Russian Museum&quot; - St / Peterburg
          <br />
          2 - Central Exhibition Palace - &quot;MANEZH&quot; St / Peterburg
          <br />
          3 - Oklahoma Museum of Modern Art - USA
          <br />
          4 - Tomsk Art Museum - Russian Federation
          <br />
          5 - in the T&amp;N collection. Moscow
          <br />
          6 &ndash; Film Academy. Paris.
          <br />
          7 - Rutgers - University. Norton Dodge Washington Collection. USA
          <br />
          8 &ndash; &quot;Nonconformism Museum&quot; - St / Petersburg -P-10
          <br />
          9 &ndash; &quot;Novi Museum&quot; - St / Petersburg
          <br />
          10 &ndash; &quot;SPAR NORD BANK&quot; - Skagen. DENMARK
          <br />
          11 &ndash; &quot;Zimmerly&quot; Museum USA
          <br />
          12 &ndash; SALD TLEMSANI - in a private collection &ndash; Casablanca
        </p>
        <br />
        <p>
          Art Manifesto
          <br />
          (10 art dice)
          <br />
          2007. S/PB
        </p>
        <br />

        <p>
          1. Describing the inner world and bringing it to the point of
          absurdity using various artistic techniques is my style and principle;
          <br />
          2. If you want to get rid of extra forms, get rid of extra time;
          <br />
          3. It is very easy to imitate other authorities, but it is brilliant
          and also difficult to be who you really are;
          <br />
          4. Monotony is fatal to the artist. To arrange everything straight
          means to take the right path;
          <br />
          5. Compliance with pauses and counterpoints, determining the necessary
          speed during the creative process will lead you to the desired result;
          <br />
          6. You are good at avoiding mistakes, but you are not able to create
          them, especially inventively. My advice: free yourself from thinking
          about mistakes;
          <br />
          7. If your right hand is already drawing like a great artist, remember
          that you also have a left hand that cannot draw, it is weak. Let him
          draw. You will find that an unmistakable and professionally crafted
          pattern is not always beautiful;
          <br />
          8. Everything you look at is a work of art. What matters is who and
          where you show it;
          <br />
          9. What I don't want, and what I want, I'm looking for.
          <br />
          10. Fear is the artist's first enemy. If you want to defeat him,
          become dangerous to him, and you will win.
        </p>
        <br />
        <p>A small excerpt from the reviews</p>
        <br />

        <p>
          - ... we can conduct an independent deep analysis of the work of
          Roland Shalamberidze, behind which lies the future. - Lyudmila
          Vorontsova. Curator of the Art Center PUSHKINSKAYA 10 projects. St.
          Petersburg..
          <br />
          - ... such boundless &quot;madness&quot; is presented in
          Shalamberidze's performances, where musical experiments,
          theatricalization and non-standard use of colors are torn apart by an
          unbridled need for self-expression, - Artem Magalashvili. Art critic -
          editor-in-chief of the magazine &quot;Art&amp;Times&quot; - St.
          Petersburg.
          <br />
          - ...Roland's work is a synthesis of various stylistic trends and
          means of self-expression&quot; - ALLAN DZODZOEV - art critic, St.
          Petersburg.
          <br />
          These excerpts from the reviews were the basis for the artist to
          receive a &quot;Green Card&quot; in the field of art in New York in
          1998, which were issued in the form of recommendations.
          <br />
          - ... in his masterpieces for the same reasons there is neither rhythm
          nor structure in the musical, pictorial exhibition of this artist -
          performances have become a big event in St. Petersburg, European
          cities, - MARIA TERENIA is a member of the International Association
          of the Arts of Criticism. St. Petersburg .<br />
          - ... the artist ROLAND SHALAMBERIDZE - points to art, free from any
          aesthetic fiction, using only the real and natural in it - it has gone
          beyond painting. IRINA MALEVANNAYA - art historian, critic.
          <br />
          - ...his music is a performance and a concert at the same time. ROLAND
          SHALAMBERIDZE has a very professional attitude to art - DAGMAR
          BERINGER - GALLERY OWNER.
          <br />
          - ... The works of ROLAND SHALAMBERIDZE are highly valued by us and
          our collectors. GALLERY - P.F. ARTS Director of the Film Academy
          Director KONSTANTIN MILAKIEV.
          <br />
          - ... surprising, but it's a fact, he manages to balance on the verge
          of absurdity and genius. HUGO DE PAGANNO - ARTSFORUM GALLERY. NEW
          YORK.
          <br />
          - ... an artist who strives to come out with primordial creativity
          approaches the magical control of natural elements and substances.
          ANDREY KHLOBISTIN - Chief Researcher, Associate Professor, Museum of
          Nonconformist Art. St. Petersburg.
          <br />
          - ...from abstract visuality to conceptualism of abstraction, this is
          how you can succinctly evaluate the creative path of ROLAND
          SHALAMBERIDZE. - SERGEY KOVALSKY. ART Center - President PUSHKINSKAYA
          10, artist.
          <br />- ... I boldly hang ANTONI TAPIS and his huge canvas in my
          collection. - He is a master of his craft. TLEMSAN SAID. GALLERIST.
          CASABLANCA.
        </p>
        <br />
      </div>
      <Link to="/">
        <div
          className="ballDiv"
          onMouseOver={() => {
            setBall(true);
          }}
          onMouseLeave={() => {
            setBall(false);
          }}
        >
          <div className="clickDiv">
            <div className={ball ? "active" : "ball"}></div>
          </div>
        </div>
      </Link>
      
      {music && player()}
    </div>
  );
}

export default Biography;

import "./main.scss";
import { Player } from "video-react";
import "../../node_modules/video-react/dist/video-react.css";
import { useState, useEffect, useContext } from "react";
import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";
import { Link } from "react-router-dom";
import { collection, onSnapshot, getFirestore } from "firebase/firestore";
import Modal from "../components/modal/Modal.jsx";

function Main() {
  let [images, setImages] = useState([
    {
      src: "https://i.ibb.co/Bc4f06J/1.jpg",
      disc: "Happy day, people 235x140cm",
    },
  ]);
  const db = getFirestore();
  async function fetchImages() {
    onSnapshot(
      collection(db, "images"),
      (docs) => {
        const items = [];
        docs.forEach((doc) => items.push(doc.data()));
        setImages(items);
        console.log(items);
      },
      (e) => {
        console.log(e);
      }
    );
  }
  useEffect(() => {
    fetchImages();
  }, []);
  const [firstRun, setFirstRun] = useState(false);
  const [ball, setBall] = useState(false);
  const [pause, setPause] = useState(false);
  const [musicSrc, setMusicSrc] = useState("");
  const [prev, setPrev] = useState(false);
  const [next, setNext] = useState(false);
  const [play, setPlay] = useState(false);
  const [activeModal, setActiveModal] = useState(false);
  let [currentPicture, setCurrentPicture] = useState(0);

  const music = [
    {
      poster: "./assets/heroinsky.png",
      author: "Roland Shalamberidze, Sergei Sokolov",
      participated:
        "Roland Shalamberidze, Sergei Sokolov, viacheslav Gaivoronskiy, Boris Bardash, Konstantin Kozlov, Viacheslav Mogilevskiy",
      soundEng: "Konstantin Kozlov",
      byUsed: "transformed tracks of musical groups and singers",
      name: "Hero In Sky CD1",
      song: "assets/cd1.mp3",
    },
    {
      poster: "./assets/heroinsky.png",
      author: "Roland Shalamberidze, Sergei Sokolov",
      participated:
        "Roland Shalamberidze, Sergei Sokolov, viacheslav Gaivoronskiy, Boris Bardash, Konstantin Kozlov, Viacheslav Mogilevskiy",
      soundEng: "Konstantin Kozlov",
      byUsed: "transformed tracks of musical groups and singers",
      name: "Hero In Sky CD2",
      song: "assets/cd2.mp3",
    },
    {
      poster: "./assets/thankyoudoctor.png",
      author: "Roland Shalamberidze, Sergei Sokolov",
      participated:
        "Roland Shalamberidze, Sergei Sokolov, viacheslav Gaivoronskiy, Boris Bardash, Konstantin Kozlov, Viacheslav Mogilevskiy",
      soundEng: "Konstantin Kozlov",
      byUsed: "transformed tracks of musical groups and singers",
      name: "Thank You Doctor",
      song: "assets/thankyoudoctor.m4a",
    },
    {
      poster: "./assets/blacklight.png",
      author: `Roland Shalamberidze Sergey Sokolov Vyacheslav Gayvoronsky Boris Bardash Konstantin Kozlov LIve/studio ST.PETERSBURG
      2003`,
      name: "Black Light",
      song: "assets/blacklight.mp3",
    },
  ];

  function playerElement() {
    function onPause() {
      setPause(false);
    }
    return (
      <div>
        <ReactJkMusicPlayer
          clearPriorAudioLists={true}
          autoPlay={true}
          audioLists={[{ musicSrc: `${localStorage.getItem("musicSrc")}` }]}
          theme="dark"
          showDownload={false}
          showPlayMode={false}
          mode="full"
          toggleMode={false}
          showThemeSwitch={false}
          onAudioPause={onPause}
        />
      </div>
    );
  }
  return (
    <>
      <div className="video">
        <Player autoPlay playsInline src="./assets/givemewater.mp4" />
      </div>
      <div className="content">
        <div className="upperground">
          <h1>UPPERGROUND</h1>
        </div>
        <div className="bio">
          <div className="picture">
            <img src="./assets/biopic.png" alt="" />
          </div>
          <div className="text">
            <p>
              Roland Shalamberidze is the founder of the UPPERGROUN movement,
              the concept of which is an extremely modern perception of the
              world - divine and cosmic artifacts, as well as empirical
              artifacts and natural physical objects, which is focused on the
              formation of a new artistic vision. The presentation of this
              course took place in the ART LIGA gallery in 2007 in St.
              Petersburg.Roland Shalamberidze is also a laureate of the 1994 St.
              Petersburg Festival of Avant-Garde Poets.Roland Shalamberidze was
              born in 1958 in Kutaisi, Georgia. In 1982, he and Merab Amaglobeli
              founded the non-conformist creative club of artists and poets
              &quot;White Wings&quot; in the city of Kutaisi. In the year of
              1993, he founded stirngs duet with Badri Lomsianidze called “LS”.
              In 2001, he founded experimental music band “White out”. Roland
              Shalamberidze lives and works in St. Petersburg since 1988. He
              works as an abstractionist, conceptualist, performance artist,
              installer, sound and video artist. His creative path began with
              successful experiments in figurative painting, but in the end he
              devoted his work to abstract expressionism and conceptualism. From
              1996 to 2001 he lived in Germany, then in New York, in year of
              1996, Roland Shalamberidze gets every documents for green card, as
              a specialist of contemporary arts in category of
              &quot;EXTRAORDINARY ABILITY&quot; on bases, recommendations of
              european galleries and museums. worked a lot under the influence
              of different ideas. In 2002 he returned to St. Petersburg. In his
              work, he uses mixed techniques and materials, combines painting,
              photography, collages, does not stop there, as he is sure that
              &quot;monotony is equal to death for the artist.&quot; Currently
              works in the St. Petersburg Art Center &quot;Pushkinskaya 10&quot;
              and in his own workshop in Tbilisi. He had 28 personal exhibitions
              and also he was a part of a many group exhibitions with other
              artists. His works exists in private collections, museums and also
              he is the author of art objects which are located in
              Casablanca(morroco)
            </p>
          </div>
        </div>
        <Link to="/biography">
          <div className="ballDiv">
            <div
              className="clickDiv"
              onMouseOver={() => {
                setBall(true);
              }}
              onMouseLeave={() => {
                setBall(false);
              }}
            >
              <div className={ball ? "active" : "ball"}></div>
            </div>
          </div>
        </Link>

        <div className="bio2">
          <div className="exhebitions">
            <div className="selectedEx">
              <h1>Selected group exhibitions:</h1>
              <p>
                <br></br>
                2017 «Icons of the Russian revolution», D10 Artspace, Geneva
                <br></br>
                2017 Moma Tbilisi, Tbilisi, Georgia.
                <br></br>
                2016 Museum of Geology, Moscow, Russia.
                <br></br>
                2016 m.galleri, Skagen, Denmark.
                <br></br>
                2014 Art-Сenter "Pushkinskaya, 10." Territioy Of Freedom" State
                Russian Museum, St. Petersburg, Russia.
                <br></br>
                2014 Didi Gallery, St. Petersburg, Russia.
                <br></br>
                2014 Gallery Katedralen, Skagen, Denmark.
                <br></br>
                2014 Museum of Nonconformist Art, St. Petersburg, Russia.
                <br></br>
                2013 Asa Art Group, St.Petersburg, Russia.
                <br></br>
                2013 IFA St. Petersburg, Russia. 2013 "Manege", St. Petersburg,
                Russia.
                <br></br>
                2012 The Museum of Nonconformist Art Art Center Pushkinskaya 10,
                St. Petersburg, Russia.
                <br></br>
                2011 "Art Moscow" the 15 international art fair, Moscow, Russia.
                <br></br>
                2010 "Space in Sebiria and far West" Khanty-Mansiysk, Omsk,
                Barnaul, Krasnoyarsk, Khabarovsk, Vladivostok, Russia.
                <br></br>
                2009 "The level of a sea" the II International Independent Art
                Festival, St.Petersburg, Russia.
                <br></br>
                2007 Central Exhibition Hall "Manezh", St.Petersburg, Russia.
                <br></br>
                2006 Kulturforum Lys over Lolland, Copenhagen, Denmark.
                Nonconformism Museum of Art Center Pushkinskaya 10,
                St.Petersburg, Russia. "Artindex" Ethnography museum,
                St.Petersburg, Russia.
                <br></br>
                2005 Gallery Katedralen, Skagen, Denmark.
                <br></br>
                2004 Central Exhibition Hall "Manezh" St.Petersburg, Russia.
                Gallery Katedralen, Skagen, Denmark.
                <br></br>
                2003 Central Exhibition Hall "Manezh" St.Petersburg, Russia.
                Gallery Katedralen, Skagen, Denmark.
                <br></br>
                2002 2004 Central Exhibition Hall "Manezh" St.Petersburg,
                Russia.
                <br></br>
                2001 Exhibition "Abstrakt Art in Russia 20th century" State
                Russian Museum, St. Petersburg, Russia.
                <br></br>
                2000 G-Real Art Gallery, 5th Avenue and 20th Street, New York,
                US. Gallery Katedralen, Skagen, Denmark.
                <br></br>
                1999 Artforum Gallery, New York Gallery Building, 24 West 57th
                Street, New York, US. Ward-Nassw Gallery, 178 Prince Street,
                SOHO, New York, US.
                <br></br>
                1998 Zalman Gallery, New York Gallery Building, 24 West 57th
                Street, New York, US. International Center, 50 West 23rd Street,
                New York, US. Dagman Berringer Gallery, Munich, Germany.
                International "Glass and Stones"
                <br></br>
                1997 Zalman Gallery, New York Gallery Building, 24 West 57th
                Street, New York, US.
                <br></br>
                1996 Gallery Peter Pich-Kralling, Germany. Gallery "Im Anbau",
                Munich, Germany. Culture Center "Cabel Fabric", Helsinki,
                Finland.
                <br></br>
                1995 Center of New Art (BYK), Viena, Austria. A.R.G.E. Montal,
                Salzburg, Austria.
                <br></br>
                1994 Gallery 103. St.Petersburg, Russia. Gallery 21
                St.Petersburg, Russia.
                <br></br>
                1993 T&I Gallery, Berlin, Germany. Akhmatova Museum, St.
                Petersburg, Russia. Nikolaevski Palace, St. Petersburg, Russia.
                Stokfors, Puktia, Finland, international project.
                <br></br>
                1992 Pankow Gallery, Rockefeller Center, Berlin, Germany.
                <br></br>
                1991 First Biennale of Contemporary
              </p>
            </div>
            <div className="manEx">
              <h1>Solo exhibitions:</h1>
              <p>
                2022 National Gallery, Rustaveli N11, Tbilisi, Georgia.
                <br />
                2017 TBC Art Gallery, Tbilisi, Georgia.
                <br />
                2016 m.galleri, Skagen, Denmark.
                <br />
                2016 L/ARTOTHEQUE, Casablanca, Morocco.
                <br />
                2014 L/ARTOTHEQUE, Casablanca, Morocco.
                <br />
                2012 L/ARTOTHEQUE, Casablanca, Mоrocco.
                <br />
                2011 Didi Gallery, St. Petersburg, Russia.
                <br />
                2010 L/ARTOTHEQUE, Casablanca, Mоrocco.
                <br />
                2010 Al Gallery, St. Petersburg, Russia.
                <br />
                2010 Gallery d'Art AU9, Casablanca, Mоrocco.
                <br />
                2009 Didi Gallery, St. Petersburg, Russia.
                <br />
                2008 Gallery d'Art AU9, Casablanca, Mоrocco.
                <br />
                2007 Exhibition center, St. Petersburg Union of Artsts, St.
                Peterburg, Russia.
                <br />
                2005 Didi Gallery, St. Petersburg, Russia.
                <br />
                2005 Indastrial hangar 1300m, St. Petersburg, Russia.
                <br />
                2002 Gellery Art&You. Kantemirovskii 2, St. Petersburg, Russia.
                <br />
                2001 LDM Palace of Youth. Popov 48, St. Petersburg, Russia.
                <br />
                2000 "Poligon", Culture Center Pushkinskaya 10, St. Petersburg,
                Russia.
                <br />
                2000 Studio "SHAL" 511, Culture Center Pushkinskaya 10, St.
                Petersburg, Russia.
                <br />
                2000 Joseph Lloyd Design Center, West 41 Street & 1st Avenue,
                New York, US.
                <br />
                1999 Citibank, Queens, New York, US.
                <br />
                1996 Studio "SHAL", Pushkinskaya 10, St. Petersburg, Russia.
                <br />
                1995 Computer Center, Copenhagen, Denmark.
                <br />
                1994 Gallery 103, St. Petersburg, Russia.
                <br />
                1993 Navigula artis, S/Petersburg. Russia
                <br />
                1993 Nikolaevsi Palace, St. Petersburg, Russia.
                <br />
                1992 Financial Center, Tampere, Finland.
                <br />
                1992 Gallery 10/10, St. Petersburg, Russia.
                <br />
                1991 New Solyanka Exhibition Hall, Moscow, Russia.
                <br />
                1985 Gallery D. Kakabadze, Kutaisi, Georgia.
              </p>
            </div>
          </div>
          <div className="music">
            {music.map((song, id) => {
              return (
                <div className="musicPost">
                  <div
                    className="left"
                    onMouseEnter={() => {
                      if (id === pause) {
                      } else {
                        setPlay(id);
                      }
                    }}
                    onMouseLeave={() => {
                      setPlay(false);
                    }}
                    onClick={() => {
                      setPlay(false);
                      if (pause === id) {
                        setPause(false);
                        setFirstRun(false);
                        console.log(firstRun);
                        setPlay(id);
                        localStorage.removeItem(`musicSrc`);
                      } else {
                        setPause(id);
                        setFirstRun(true);
                        setMusicSrc(song?.song);
                        localStorage.setItem("musicSrc", `${song.song}`);
                      }
                    }}
                  >
                    <div className="playDiv">
                      <img
                        className={id === play ? "play" : "none"}
                        src="assets/play.png"
                        alt=""
                      />
                      <img
                        className={id === pause ? "pause active" : "pause"}
                        src="assets/pause.png"
                        alt=""
                      />
                    </div>
                    <img className="poster" src={song.poster} alt="" />
                  </div>
                  <div className="right">
                    <div className="name">
                      <h1>{song.name}</h1>
                    </div>
                    <p>Project by {song.author}</p>
                    <p>
                      {song.participated &&
                        `partipicated: ${song.participated}`}
                    </p>
                    <p>{song.soundEng && `Sound engineer-${song.soundEng}.`}</p>
                  </div>
                </div>
              );
            })}
            <div className="musicPost">
              <div
                className="left"
                onMouseEnter={() => {
                  if ("video" === pause) {
                  } else {
                    setPlay("video");
                  }
                }}
                onMouseLeave={() => {
                  setPlay(false);
                }}
                onClick={() => setActiveModal(true)}
              >
                <div className="playDiv">
                  <img
                    className={"video" === play ? "play" : "none"}
                    src="assets/play.png"
                    alt=""
                  />
                  <img
                    className={"video" === pause ? "pause active" : "pause"}
                    src="assets/pause.png"
                    alt=""
                  />
                </div>
                <img
                  className="poster"
                  src="https://firebasestorage.googleapis.com/v0/b/standardrolandart-2b557.appspot.com/o/305494280_3320617434834633_8595397838685927191_n.jpg?alt=media&token=8fe85003-510f-4ce9-bc8a-a7a46ea6647c"
                  alt=""
                />
              </div>
              <div className="right">
                <div className="name">
                  <h1>UPPERGROUND (VIDEO)</h1>
                </div>
              </div>
              <Modal active={activeModal} setActive={setActiveModal}>
              <Player src="./assets/upperground.mp4" />
              </Modal>
            </div>
          </div>
        </div>
        <div className="slider">
          <div className="picture">
            <img src={`${images[currentPicture].src}`} alt="" />
          </div>
          <div className="buttons">
            <div className="prev">
              <div
                className="clickDiv"
                onMouseOver={() => {
                  setPrev(true);
                }}
                onMouseLeave={() => {
                  setPrev(false);
                }}
              >
                <div
                  className={prev ? "active" : "ball"}
                  onClick={() => {
                    if (currentPicture == 0) {
                      setCurrentPicture(images.length - 1);
                    } else {
                      setCurrentPicture(--currentPicture);
                    }
                  }}
                ></div>
              </div>
              <div className="disc">
                <p>{images[currentPicture].disc}</p>
              </div>
            </div>
            <div
              className="next"
              onClick={() => {
                if (currentPicture == images.length - 1) {
                  setCurrentPicture(0);
                } else {
                  setCurrentPicture(++currentPicture);
                }
              }}
            >
              <div
                className="clickDiv"
                onMouseOver={() => {
                  setNext(true);
                }}
                onMouseLeave={() => {
                  setNext(false);
                }}
              >
                <div className={next ? "active" : "ball"}></div>
              </div>
            </div>
          </div>
        </div>
        <div className="red">
          <img src="assets/prujina3.png" alt="" />
        </div>
        <div className="footer">
          <div className="footer-content">
            <div className="social">
              <h1
                onClick={() => {
                  window.open(
                    "https://www.facebook.com/profile.php?id=100082629297454"
                  );
                }}
              >
                Facebook
              </h1>
              <h1
                onClick={() => {
                  window.open(
                    "https://youtube.com/channel/UCO53DhCicMmgCGqwsLBj1iQ"
                  );
                }}
              >
                Youtube
              </h1>
              <h1
                onClick={() => {
                  window.open("https://www.saatchiart.com/rolandart");
                }}
              >
                Saatchi
              </h1>
            </div>
            <div className="contact">
              <div className="left">
                <p>Team number - +995598498884</p>
                <p>Personal number -+995591922880</p>
              </div>
              <div className="right">
                <p>Team email - standardrolandartgmail.com</p>
                <p>Personal email - rolandart1958@gmail.com</p>
              </div>
            </div>
          </div>
        </div>
        {localStorage.getItem(`musicSrc`) && playerElement()}
      </div>
    </>
  );
}

export default Main;

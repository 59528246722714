import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyCvws_fsCNOLPMcNR3e9tpSF-vnYAazPy0",
    authDomain: "standardrolandart-2b557.firebaseapp.com",
    projectId: "standardrolandart-2b557",
    storageBucket: "standardrolandart-2b557.appspot.com",
    messagingSenderId: "921301009015",
    appId: "1:921301009015:web:35f7f366e6b3fb61e27c72",
    measurementId: "G-GQKL91S2KS"
  };

export const app = initializeApp(firebaseConfig);